import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navbar, ResutlCard } from "../../components";
import { FadeLoader } from "react-spinners";

function Result() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [surveyData, setSurverData] = useState();
  const [finalResult, setFinalResult] = useState();

  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      let { finalResult, surveyData } = state;
      setSurverData(surveyData);
      setFinalResult(finalResult);
    }
  }, []);
  return (
    <div className="w-full">
      {surveyData && finalResult ? (
        <div className="w-screen ">
          <Navbar hideModal={true} path={surveyData.companyInfo.url} />
          <div
            hideModal={true}
            style={{
              backgroundImage: `url(${
                surveyData && surveyData.companyInfo.bg
              })`,
            }}
            className={`w-full bg-cover bg-no-repeat`}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              className="px-4 py-2"
            >
              <div className="lg:flex lg:items-start md:flex md:items-start justify-center 2xl:mt-32 lg:mt-10 md:10 mt-6 ">
                <ResutlCard data={finalResult[0]} title="Your closest match" />
                <div className="2xl:border-l-2 2xl:border-l-neutral-400  lg:border-l-2 lg:border-l-neutral-400 border-0">
                  {finalResult &&
                    finalResult.map((info, i) => {
                      return i !== 0 ? (
                        <ResutlCard
                          key={i}
                          id={i}
                          data={info}
                          title="Other pathways to consider"
                        />
                      ) : null;
                    })}

                  <div className="flex items-center justify-center  ">
                    <img
                      src={surveyData.companyInfo.powered}
                      style={{
                        width: 200,
                        height: 50,
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <FadeLoader
            height={20}
            width={5}
            radius={2}
            margin={4}
            color="#CC0000"
          />
        </div>
      )}
    </div>
  );
}

export default Result;
