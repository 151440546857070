

// it will shuffle the array of answers for every question
// 1) question, 2) array of answers, 3)background image url, type ==> {single select or multiselect so we can show the component as per need}

const shuffleArray = (question, array, url, type) => {
  for (var i = array.length - 1; i > 0; i--) {
    // Generate random number
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  let singleQuestion = {
    question,
    options: array,
    url,
    type,
  };

  return singleQuestion;
};

// handle data response


export { shuffleArray };
