import React from "react";

function ResultCard(props) {
  console.log(props,' here are propss')
  return (
    <div className="2xl:pb-15 pb-4">
      <div className="flex flex-col justify-center items-center">
        {props.id == 2 ? null : (
          <h1 className="shadow-lg text-white py-3 2xl:px-32 px-10 pb-4 rounded-md font-bold 2xl:text-4xl lg:text-3xl md:text-3xl text-3xl">
            {props.title}
          </h1>
        )}
        <h1 className="shadow-lg bg-[#cc0000] py-3 2xl:px-32 px-20 rounded-md font-bold 2xl:text-4xl lg:text-2xl md:text-2xl text-base text-white">
          {props.data.heading}
        </h1>
        <p className="2xl:w-7/12 lg:w-8/12 md:w-8/12  text-justify 2xl:text-xl   text-sm text-white my-6">
          {props.data.description}
        </p>

        <h5
          onClick={() => {
            window.open(props.data.url, "_blank");
          }}
          className={`hover:text-[#CC0000] hover:border-[#CC0000] text-white bg-black font-bold text-lg  border-b cursor-pointer px-4 py-3`}
        >
          Learn More
        </h5>
      </div>
    </div>
  );
}

export default ResultCard;
