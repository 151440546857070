import React from "react";
import { HashRouter , Route, Routes } from "react-router-dom";
import { Home, Quiz, Result } from "../pages";

const BainRouting = () => {
  return (
    <HashRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </HashRouter>
  );
};

export default BainRouting;
