import React, { useState, useEffect } from "react";

function LeftCard(props) {
  let { item, id } = props;
  const [selected, selectedValue] = useState();
  let handelSelect = (ans, ques) => {
    let question = ques.split(".")[0];
    let item = {
      question,
      answer: ans,
    };
    selectedValue(item.answer);
    props.sendData(item, id, selected);
  };

  useEffect(() => {
    selectedValue(props.answer);
  }, [item]);
  return (
    <div className=" flex flex-col mt-10 md:px-12 lg:px-32 px-4  ">
      <h1 className="text-white border-b-2 border-[#cc0000] pb-2 font-bold w-fit  2xl:text-4xl lg:text-3xl md:text-2xl text-xl">
        {item.question}
      </h1>
      <div className="w-auto mt-4">
        {item.options.map((option, i) => {
          return (
            <label
              onClick={() => handelSelect(option, item.url)}
              key={i}
              className={`drop-shadow-xl ${
                selected === option ? "bg-[#cc0000]" : null
              } ${
                selected === option ? "text-white" : null
              } hover:bg-[#cc0000] w-fit text-white 2xl:py-4 py-2 mb-3 rounded-md flex items-center lg:px-4 md:px-4 px-2 2xl:text-xl lg:text-xl  text-base`}
            >
              {option}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default LeftCard;
