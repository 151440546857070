import React, { useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { Navbar, LeftCard, MultiSelect } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import { postApi } from "../../services/apiCall";
import { ref, push } from "firebase/database";
import { shuffleArray } from "./quizActions";
import { app, db } from "../../firebase";
import "./quiz.css";

function Quiz() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [surveyData, setSurverData] = useState();
  const [current, setCurrent] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [result, setResult] = useState([]);
  const [quiz, setQuiz] = useState();
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState();

  // stepper handel for every next question
  const next = () => {
    let getNextQuestion = shuffleArray(
      surveyData.quiz[current + 1].question,
      surveyData.quiz[current + 1].options,
      surveyData.quiz[current + 1].url,
      surveyData.quiz[current + 1].type
    );
    setQuiz(getNextQuestion);
    setCurrent(current + 1);

    if (typeof result[current + 1] === "undefined") {
      setDisabled(!disabled);
    } else {
      setAnswer(result[current + 1].answer);
      setDisabled(false);
    }
  };

  const previous = () => {
    let getNextQuestion = shuffleArray(
      surveyData.quiz[current - 1].question,
      surveyData.quiz[current - 1].options,
      surveyData.quiz[current - 1].url,
      surveyData.quiz[current - 1].type
    );
    setCurrent(current - 1);
    setAnswer(result[current - 1].answer);
    setQuiz(getNextQuestion);
    setDisabled(false);
  };

  // on submit the quiz send data to result screen
  let resutlFun = async (result, resultCardData) => {
    let data = result;
    let apiCallRequest = {
      url: "https://ujagar2.ca/scorev2",
      data,
    };

    return postApi(apiCallRequest)
      .then(async (res) => {
        let obj = res.data;
        let finalResult = [];

        finalResult.push(resultCardData[obj["First"]]);
        finalResult.push(resultCardData[obj["Second"]]);
        finalResult.push(resultCardData[obj["Third"]]);
        let currentTime = new Date();

        let formattedDate = currentTime.toISOString().slice(0, 19).replace('T', ' ');


        let completeResponseObj = {
          response: obj,
          result: result,
          surveyCreateAt: Date.now(),
        };
        completeResponseObj.createdDate = formattedDate;

        await push(ref(db, "data/"), completeResponseObj);

        return {
          loading: false,
          finalResult,
        };
      })
      .catch((er) => {
        return er;
      });
  };
  const submit = async () => {
    setLoading(true);
    await resutlFun(result, surveyData.resultInfoObject)
      .then((res) => {
        let data = res;
        console.log("data added!");
        setLoading(data.loading);
        navigate("/result", {
          state: {
            surveyData,
            finalResult: data.finalResult,
          },
        });
      })
      .catch((er) => {
        alert(er.message);
      });
  };

  // get data from components and save in array
  const handleCurrentQuestion = (item, i, answer) => {
    if (result.length > 0) {
      result.splice(i, 1, item);
      setDisabled(false);
      setResult(result);
    } else {
      let data = [];
      data.push(item);
      setDisabled(false);
      setResult(data);
    }
  };

  const getMultipleSelectData = (item, i, ques) => {
    if (!item) {
      setDisabled(true);
    } else {
      let obj = {
        question: ques,
        answer: item,
      };
      result.splice(i, 1, obj);
      setDisabled(false);
      setResult(result);
    }
  };

  // get data from home screen to render quiz
  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      let { surveyData } = state;
      let allQuestions = surveyData.quiz;
      let getQuestion = shuffleArray(
        allQuestions[current].question,
        allQuestions[current].options,
        allQuestions[current].url,
        "single"
      );
      setQuiz(getQuestion);
      setSurverData(surveyData);
    }
  }, []);

  useEffect(() => {
    console.log(current);
  }, [current]);

  return (
    <>
      {surveyData && !loading ? (
        <div
          style={{
            backgroundImage: `url(${
              window.location.origin + quiz && quiz.url
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="imgContainer w-screen 2xl:h-screen   h-full"
        >
          <div className="textContainer w-screen 2xl:h-screen  h-full">
            <Navbar path={surveyData.companyInfo.url} />
            <div>
              <div>
                <Stepper
                  activeStep={current}
                  showNavigation={true}
                  steps={surveyData.quiz}
                  activeColor="#cc0000"
                  completeColor="#cc0000"
                  titleTop={0}
                  circleTop={25}
                  lineMarginOffset={0}
                  defaultColor="black"
                  defaultBarColor="white"
                  completeBarColor="#cc0000"
                />

                {quiz.type === "multiSelect" ? (
                  <MultiSelect
                    answer={answer}
                    id={current}
                    item={quiz}
                    sendMultipleSelectData={(val, id, ques) =>
                      getMultipleSelectData(val, id, ques)
                    }
                  />
                ) : (
                  <LeftCard
                    answer={answer}
                    id={current}
                    item={quiz}
                    sendData={(val, id, answer) =>
                      handleCurrentQuestion(val, id, answer)
                    }
                  />
                )}

                <div
                  className={`lg:px-36 md:px-12 px-8 pb-8 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl-row md:flex lg: ${
                    quiz.type === "multiSelect"
                      ? "justify-between md:justify-between lg:justify-between xl:justify-between 2xl:justify-between"
                      : " justify-center md:justify-between lg:justify-between xl:justify-between 2xl:justify-between"
                  } items-center  mt-6`}
                >
                  {
                    <button
                      onClick={previous}
                      className={`${
                        (current == 0 || current == 10) && "hidden"
                      } lg:w-4/12 md:w-4/12 sm:w-4/5 w-full rounded-md bg-[#CC0000] text-white"
                      text-white font-bold lg:mt-0  py-3 px-10  border-none`}
                    >
                      Previous
                    </button>
                  }
                  {current < surveyData.quiz.length && (
                    <button
                      disabled={disabled}
                      onClick={
                        current === surveyData.quiz.length - 1
                          ? () => submit()
                          : () => next()
                      }
                      className={`lg:w-4/12 md:w-4/12 sm:w-4/5 w-full rounded-md ${
                        disabled
                          ? "bg-gray-400 text-black mt-2"
                          : "bg-[#CC0000] text-white mt-2"
                      } text-white font-bold lg:mt-0  py-3 px-10  border-none`}
                    >
                      {current === surveyData.quiz.length - 1
                        ? "Submit"
                        : "Next"}
                    </button>
                  )}
                </div>
                <div
                  className={`
              
              2xl:pb-0 xl:pb-0 lg:pb-0 md:pb-0 ${
                current === 3 || current === 4 || current === 5
                  ? "pb-16"
                  : "pb-0"
              }
              `}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <FadeLoader
            height={20}
            width={5}
            radius={2}
            margin={4}
            color="#CC0000"
          />
        </div>
      )}
    </>
  );
}

export default Quiz;
