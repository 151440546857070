import React, { Fragment } from "react";
import BainRoutes from "./routing";
import "./App.css";

const App = () => {

  return (
    <Fragment>
      {process.env.NODE_ENV !== "development"
        ? (console.log = function () {})
        : null}
      <BainRoutes />
    </Fragment>
  );
};

export default App;
