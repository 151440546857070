import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCyVyy0OHyIDDGqD4Gg0Zm1MIVSZ2sT9Q4",
  authDomain: "bain-79747.firebaseapp.com",
  projectId: "bain-79747",
  storageBucket: "bain-79747.appspot.com",
  messagingSenderId: "454726278722",
  appId: "1:454726278722:web:687023951013eb99e657db",
  measurementId: "G-N0DL2ZQHEQ",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export { app, db };
