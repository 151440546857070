import React, { useState } from "react";

function RightCard(props) {
  let { item, id } = props;
  const [selected, selectedValue] = useState();

  let handelSelect = (item) => {
    selectedValue(item);
    props.sendData(item, id);
  };
  return (
    <div className="mt-10 md:px-12 lg:px-32 px-0 flex flex-col items-end">
      <h1 className="text-white font-bold lg:w-2/3 md:w-2/3 w-full  2xl:text-4xl lg:text-4xl md:text-2xl text-xl text-right">
        {item.question}
      </h1>
      <p className="lg:w-1/2 md:w-1/2 w-full border mt-2 border-[#cc0000] bg-[#cc0000]"></p>

      <div className="w-auto flex flex-col items-end mt-4">
        {item.options.map((option, i) => {
          return (
            <label
              onClick={() => handelSelect(option)}
              key={i}
              className={`drop-shadow-xl w-2/3 ${
                selected === option ? "bg-[#cc0000]" : null
              } ${
                selected === option ? "text-white" : null
              } hover:bg-[#cc0000] w-fit 2xl:py-4 text-white text-right py-2 mb-3 rounded-md px-4  2xl:text-xl lg:text-xl  text-base`}
            >
              {option}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default RightCard;
