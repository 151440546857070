import axios from "axios";

async function postApi(apiCallRequest) {
  return await axios.post(
    apiCallRequest.url,
    apiCallRequest.data
  );
}

export { postApi };
