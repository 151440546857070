import React from "react";

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
    };
  }

  handleButton = (button, ques) => {
    let question = ques.split(".")[0];

    this.setState({
      selected: button,
    });

    this.props.sendMultipleSelectData(button, this.props.id, question);
  };

  componentDidMount() {
    this.setState({
      selected: this.props.answer,
    });
  }
  render() {
    let { item } = this.props;
    let { selected } = this.state;
    return (
      <div className="mt-10 md:px-32 lg:px-32 px-0 flex flex-col justify-center items-center">
        <h1 className="text-white  font-bold border-b-2 border-[#cc0000] pb-2  w-fit  2xl:text-4xl lg:text-3xl md:text-2xl text-xl text-center">
          {item.question}
        </h1>

        <div className="2xl:w-9/12 lg:w-full md:w-9/12 mt-12  flex items-center flex-wrap justify-center">
          {item.options.map((bt, i) => (
            <button
              key={i}
              onClick={() => this.handleButton(bt, item.url)}
              className={`border-2 border-[#cc0000] hover:bg-[#cc0000]  mx-10 text-white py-4 my-4 font-bold  2xl:w-[25%] lg:w-[25%]  md:w-screen w-full ${
                selected === bt ? "bg-[#cc0000]" : null
              }`}
            >
              {bt}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default MultiSelect;
